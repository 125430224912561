
.locationbar__container{
    position: relative;
    background: #FFFFFF;
    border: 1px solid #1565D8;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    width:  30vw;
    height: 32%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline-width: 0;
    padding-left: 1%;
}

.search__locationbar{
    border: none;
    height: 100%;
    font-weight: normal;
    font-size: 1em;
    color: #656363;
    outline-width: 0;
   width: 100%;
    position: relative;
    border-radius: 6px;
    
}
.wrapper{
    height: 100%;
    width: 85%;
    max-width: 90%;
}

.suggestion__container{
    background-color: white;
    max-width: 25vw;
    width: 25vw;
    padding:5px;
}
@media only screen and (max-width: 1024px) {
    .search__locationbar{
       font-size: 0.7em;
        
    }
  }
  @media only screen and (max-width: 805px) {
    .search__locationbar{
        font-size: 0.6em;
     }
  }
  @media only screen and (max-width: 688px) {
    .search__locationbar{
        font-size: 0.5em;
     }
  }
  @media only screen and (max-width: 560px) {
    .locationbar__container{
        width: 25vw;
     }
  }
  @media only screen and (max-width: 415px) {
    .locationbar__container{
        width: 25vw;
     }
     .search__locationbar{
        font-size: 0.4em;
     }
  }