@import url(https://fonts.googleapis.com/css2?family=Gothic+A1:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* border: 1px solid #f00 !important; */
}
body{
  width: 100%;
}
.navbar__container{
display: flex;
align-items: center;
justify-content: space-between;
height: 16.578249336870027vh;
padding-right: 5%;
}
.logo__container{
    display: flex;
    align-items: center;
}
.logo__container img{
    max-height: 100%;
}
.logo__container h4{
    margin-left: 2vw;
    font-family:'Gothic A1', sans-serif;
    font-weight: 700;
    color: #EC1F27;
    font-size: 3.9787798408488064vh;
}
.shop__searchbar{
    border: none;
    height: 100%;
    border-bottom: 1px solid black;
    font-weight: normal;
    font-size: 20px;
    color: #656363;
    outline-width: 0;
}
.searchbar__container{
    height: 30%;
}
.logout__button{
   display: flex;
   align-items: center;
   justify-content: center; 
}
.logout__button button{
    border: 0;
    background-color: #ff7379;
    color: white;
    width: 10vw;
    height: 5vh;
    cursor: pointer;
    outline-width: 0ch;
}
.md-ui.component-pagination {
    list-style: none;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .md-ui.component-pagination li {
    border-radius: 100%;
    color: #212121;
    display: inline-block;
    margin-left: 2vw;
    margin-right: 2vw;
    transition: 0.15s ease-in;
    cursor: default;
  }
  .current-number,.pagination-arrow{
    cursor: pointer!important;
  }
  .pagination-number{
    background: rgba(233, 30, 98, 0.226);
  }

  .md-ui.component-pagination .pagination-number,
  .md-ui.component-pagination .pagination-arrow i {
    vertical-align: middle;
  }
  .md-ui.component-pagination .pagination-number {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    min-width: 40px;
    padding: 8px 0;
  }
  .md-ui.component-pagination .pagination-number.current-number {
    background:  #FF7379;
    color: white;
  }
  .md-ui.component-pagination .pagination-arrow i {
    font-size: 24px;
    padding: 8px;
  }
.background_none{
  background: none!important;
}
.search__container{
    display: flex;
    width: 100%;
    min-height: 70vh;
    align-items: center;
    justify-content: center;
    padding-left: 5vw;
}
.search__aside{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;   
}
.search__spacer{
    height: 5vh;
    background-color: #F6F7F8;
}
.search__components h2{
padding-top: 5vh;
padding-bottom: 5vh;
padding-left: 4vw;
font-size: 18px;
line-height: 21px;
text-decoration: underline;
color: #000000;
}
.search__aside{
    padding-top: 5vh;
    padding-left: 2vw;
    padding-right: 2vw;
}
.loading__container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading__bg{
    background-image: url(/static/media/loader.ae65c16d.png);
    background-size: contain;
    height: 50vh;
    width: 50vh;
    background-repeat: no-repeat;
    background-position: center;
}
.shop__container{
    display: flex;
    position: relative;
    height: 30vh;
    margin-bottom: 5vh;
}
.shop__image{
    position: relative;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 30%;
    background-image: url(/static/media/search__background.0803314e.png);
}
.shop__discription{
    margin-right: 5vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5vw;
}
.shop__discription h2{
font-family: 'Poppins', sans-serif;
font-weight: 500;
font-size: 24px;
line-height: 36px;
color: #22262A;
}
.shop__discription p{
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;

color: #686868;
}

.shop__discription h4{

    font-size: 14px;
    line-height: 21px;
    color: #262626;
}
.shop__visit{
background: rgba(51,160, 255, 0.1);
color: #1F7AC9;
font-family: 'Poppins', sans-serif;
font-size: 14px;
width: 20%;
text-align: center;
font-weight: 500;
text-decoration: none;
height: 20%;
display: flex;
align-items: center;
justify-content: center;
}
.footer__container{
    min-height: 30vh;
    margin-top: 5vh;
    height: 30vh;
    background-color:  #3B3B3B;
}
.form__component{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: 80%;
}
h4{
  color: #696F79;font-weight: 500;
  font-size: 18px;
}
.form__namenumber{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.form__input{
background: #FFFFFF;
border: 1px solid #1565D8;
box-sizing: border-box;
box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
border-radius: 6px;
width:  30vw;
height: 6vh;
outline-width: 0;
margin-bottom: 5vh;
padding: 1%;
}
.form__input:focus{
  border: 1px solid #FF7379;
}
.PhoneInputCountry{
  min-width: 25%!important;
}
.PhoneInputInput{
  border: none!important;
  outline-width: 0!important;
}
.form__weekcontainer{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  flex-direction: column;
  margin-bottom: 5vh;
}
.form__weekscontainer h4{
  color: #525252;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1vh;
}

.shop__opens{
  margin-bottom: 2vh;
}
.form__weeks {
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
}
.form__timecontainer{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 40%;
  align-items: flex-start;
  margin-bottom: 5vh;
}
.form__aboutshop{
background: #FFFFFF;
border: 1px solid #1565D8;
box-sizing: border-box;
box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
border-radius: 6px;
width:  100%;
height: 20vh;
outline-width: 0;
margin-bottom: 5vh;
padding-left: 1%;
}
.form__websitelink{
  height: 6vh!important;
}
.submit__btn{
background: #FF7379;
border-radius: 6px;
color: white;
border: none;
outline-width: 0;
height: 5vh;
width: 5vw;
cursor: pointer;
}
.form__weekscontainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.form__servicecontainer{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5vh;
}
.form__servicecontainer h4{
  color: #525252;
  font-weight: 500;
  font-size: 16px;
  margin-right: 2vh;
}
.form__services{
  display: flex;
  justify-content: center;
  align-items: center;
}
.form__error{
height: 20vh;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
font-size: large;
color: red;
}
@media only screen and (max-width: 1030px) {
  .form__namenumber{
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;
  }
  .form__input{
      width: 100%;
  }
  .location__container{
      width: 100%;
  }
  .form__weekcontainer{
      margin-top: 5vh;
      width: 100%;
  }
  .form__timecontainer{
      width: 100%;
  }
  .submit__btn{
      width: 100px;
  }
}
@media only screen and (max-width: 945px){
    .form__servicecontainer{
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    .form__services{
        width: 50%;
        display: flex;
        justify-content: space-between;
    }
    
}
@media only screen and (max-width: 560px){
  .form__services{
      width: 80%;
  }
  
}

.locationbar__container{
    position: relative;
    background: #FFFFFF;
    border: 1px solid #1565D8;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    width:  30vw;
    height: 32%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline-width: 0;
    padding-left: 1%;
}

.search__locationbar{
    border: none;
    height: 100%;
    font-weight: normal;
    font-size: 1em;
    color: #656363;
    outline-width: 0;
   width: 100%;
    position: relative;
    border-radius: 6px;
    
}
.wrapper{
    height: 100%;
    width: 85%;
    max-width: 90%;
}

.suggestion__container{
    background-color: white;
    max-width: 25vw;
    width: 25vw;
    padding:5px;
}
@media only screen and (max-width: 1024px) {
    .search__locationbar{
       font-size: 0.7em;
        
    }
  }
  @media only screen and (max-width: 805px) {
    .search__locationbar{
        font-size: 0.6em;
     }
  }
  @media only screen and (max-width: 688px) {
    .search__locationbar{
        font-size: 0.5em;
     }
  }
  @media only screen and (max-width: 560px) {
    .locationbar__container{
        width: 25vw;
     }
  }
  @media only screen and (max-width: 415px) {
    .locationbar__container{
        width: 25vw;
     }
     .search__locationbar{
        font-size: 0.4em;
     }
  }
.locate__btn{
    border: none;
    background-color: transparent;
    cursor: pointer;
    height: 100%;
   outline-width: 0;
}


.admin__background{
    background-image: url(/static/media/admin__background.58494908.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: -2;
}
.admin__bgfilter{
    z-index: -1;
position: absolute;
height: 100%;
width: 100%;
background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 90.94%);
}
.admin__background h2{
font-family: Inter;
width: 30%;
font-style: normal;
font-weight: bold;
font-size: 4.278074866310161vw;
line-height: 4.078074866310161vw;
color: #FF7379;
}
.admin__background span{
    color:white;
    line-height: 4.078074866310161vw;
}
.admin__formcontainer{
    margin-top: 5vh;
    padding-left: 5vw;
    padding-right: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
}
.form__component{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    width: 80%;
  }
  h4{
    color: #696F79;font-weight: 500;
    font-size: 18px;
  }
  .form__namenumber{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .form__input{
  background: #FFFFFF;
  border: 1px solid #1565D8;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  width:  30vw;
  height: 6vh;
  outline-width: 0;
  margin-bottom: 5vh;
  padding: 1%;
  }
  .form__input:focus{
    border: 1px solid #FF7379;
  }
  .PhoneInputCountry{
    min-width: 25%!important;
  }
  .PhoneInputInput{
    border: none!important;
    outline-width: 0!important;
  }
  .form__weekcontainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    flex-direction: column;
    margin-bottom: 5vh;
  }
  .form__weekscontainer h4{
    color: #525252;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 1vh;
  }
  
  .shop__opens{
    margin-bottom: 2vh;
  }
  .form__weeks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  }
  .form__timecontainer{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
    align-items: flex-start;
    margin-bottom: 5vh;
  }
  .form__aboutshop{
  background: #FFFFFF;
  border: 1px solid #1565D8;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  width:  100%;
  height: 20vh;
  outline-width: 0;
  margin-bottom: 5vh;
  padding-left: 1%;
  }
  .form__websitelink{
    height: 6vh!important;
  }
  .submit__btn{
  background: #FF7379;
  border-radius: 6px;
  color: white;
  border: none;
  outline-width: 0;
  height: 5vh;
  width: 5vw;
  cursor: pointer;
  }
  .form__weekscontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  .form__servicecontainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vh;
  }
  .form__servicecontainer h4{
    color: #525252;
    font-weight: 500;
    font-size: 16px;
    margin-right: 2vh;
  }
  .form__services{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form__error{
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: large;
  color: red;
  }
  @media only screen and (max-width: 1030px) {
    .form__namenumber{
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
    }
    .form__input{
        width: 100%;
    }
    .location__container{
        width: 100%;
    }
    .form__weekcontainer{
        margin-top: 5vh;
        width: 100%;
    }
    .form__timecontainer{
        width: 100%;
    }
    .submit__btn{
        width: 100px;
    }
  }
  @media only screen and (max-width: 945px){
      .form__servicecontainer{
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
      }
      .form__services{
          width: 50%;
          display: flex;
          justify-content: space-between;
      }
      
  }
  @media only screen and (max-width: 560px){
    .form__services{
        width: 80%;
    }
    
  }
.form__component{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    width: 80%;
  }
  h4{
    color: #696F79;font-weight: 500;
    font-size: 18px;
  }
  .form__namenumber{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .form__input{
  background: #FFFFFF;
  border: 1px solid #1565D8;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  width:  30vw;
  height: 6vh;
  outline-width: 0;
  margin-bottom: 5vh;
  padding: 1%;
  }
  .form__input:focus{
    border: 1px solid #FF7379;
  }
  .PhoneInputCountry{
    min-width: 25%!important;
  }
  .PhoneInputInput{
    border: none!important;
    outline-width: 0!important;
  }
  .form__weekcontainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    flex-direction: column;
    margin-bottom: 5vh;
  }
  .form__weekscontainer h4{
    color: #525252;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 1vh;
  }
  
  .shop__opens{
    margin-bottom: 2vh;
  }
  .form__weeks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  }
  .form__timecontainer{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
    align-items: flex-start;
    margin-bottom: 5vh;
  }
  .form__aboutshop{
  background: #FFFFFF;
  border: 1px solid #1565D8;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  width:  100%;
  height: 20vh;
  outline-width: 0;
  margin-bottom: 5vh;
  padding-left: 1%;
  }
  .form__websitelink{
    height: 6vh!important;
  }
  .submit__btn{
  background: #FF7379;
  border-radius: 6px;
  color: white;
  border: none;
  outline-width: 0;
  height: 5vh;
  width: 5vw;
  cursor: pointer;
  }
  .form__weekscontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  .form__servicecontainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vh;
  }
  .form__servicecontainer h4{
    color: #525252;
    font-weight: 500;
    font-size: 16px;
    margin-right: 2vh;
  }
  .form__services{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form__error{
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: large;
  color: red;
  }
  @media only screen and (max-width: 1030px) {
    .form__namenumber{
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
    }
    .form__input{
        width: 100%;
    }
    .location__container{
        width: 100%;
    }
    .form__weekcontainer{
        margin-top: 5vh;
        width: 100%;
    }
    .form__timecontainer{
        width: 100%;
    }
    .submit__btn{
        width: 100px;
    }
  }
  @media only screen and (max-width: 945px){
      .form__servicecontainer{
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
      }
      .form__services{
          width: 50%;
          display: flex;
          justify-content: space-between;
      }
      
  }
  @media only screen and (max-width: 560px){
    .form__services{
        width: 80%;
    }
    
  }
.login__container{
    min-height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10% 5%;
    align-items: center;
}
.login__form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30vh;
    margin-top: 5vh;
    align-items: center;
    width: 50vw;
    padding: 5vh;
}
.login__field{
    border: 1px solid #8692A6;
    box-sizing: border-box;
    border: 1px solid black;
    height: 5vh;
    width: 50%;
    padding: 0.5vh;
    font-size: 14px;
}
.login__submit{
    width: 25%;
    height: 5vh;
    border: none;
    background-color: #FF7379;
    color: white;
    cursor: pointer;
}

