.admin__background{
    background-image: url("../../assets/admin__background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: -2;
}
.admin__bgfilter{
    z-index: -1;
position: absolute;
height: 100%;
width: 100%;
background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 90.94%);
}
.admin__background h2{
font-family: Inter;
width: 30%;
font-style: normal;
font-weight: bold;
font-size: 4.278074866310161vw;
line-height: 4.078074866310161vw;
color: #FF7379;
}
.admin__background span{
    color:white;
    line-height: 4.078074866310161vw;
}
.admin__formcontainer{
    margin-top: 5vh;
    padding-left: 5vw;
    padding-right: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
}