.loading__container{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading__bg{
    background-image: url("../../assets/loader.png");
    background-size: contain;
    height: 50vh;
    width: 50vh;
    background-repeat: no-repeat;
    background-position: center;
}