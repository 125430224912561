@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@700&display=swap');
.navbar__container{
display: flex;
align-items: center;
justify-content: space-between;
height: 16.578249336870027vh;
padding-right: 5%;
}
.logo__container{
    display: flex;
    align-items: center;
}
.logo__container img{
    max-height: 100%;
}
.logo__container h4{
    margin-left: 2vw;
    font-family:'Gothic A1', sans-serif;
    font-weight: 700;
    color: #EC1F27;
    font-size: 3.9787798408488064vh;
}
.shop__searchbar{
    border: none;
    height: 100%;
    border-bottom: 1px solid black;
    font-weight: normal;
    font-size: 20px;
    color: #656363;
    outline-width: 0;
}
.searchbar__container{
    height: 30%;
}
.logout__button{
   display: flex;
   align-items: center;
   justify-content: center; 
}
.logout__button button{
    border: 0;
    background-color: #ff7379;
    color: white;
    width: 10vw;
    height: 5vh;
    cursor: pointer;
    outline-width: 0ch;
}