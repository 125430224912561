.login__container{
    min-height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10% 5%;
    align-items: center;
}
.login__form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30vh;
    margin-top: 5vh;
    align-items: center;
    width: 50vw;
    padding: 5vh;
}
.login__field{
    border: 1px solid #8692A6;
    box-sizing: border-box;
    border: 1px solid black;
    height: 5vh;
    width: 50%;
    padding: 0.5vh;
    font-size: 14px;
}
.login__submit{
    width: 25%;
    height: 5vh;
    border: none;
    background-color: #FF7379;
    color: white;
    cursor: pointer;
}
