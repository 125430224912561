.md-ui.component-pagination {
    list-style: none;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .md-ui.component-pagination li {
    border-radius: 100%;
    color: #212121;
    display: inline-block;
    margin-left: 2vw;
    margin-right: 2vw;
    transition: 0.15s ease-in;
    cursor: default;
  }
  .current-number,.pagination-arrow{
    cursor: pointer!important;
  }
  .pagination-number{
    background: rgba(233, 30, 98, 0.226);
  }

  .md-ui.component-pagination .pagination-number,
  .md-ui.component-pagination .pagination-arrow i {
    vertical-align: middle;
  }
  .md-ui.component-pagination .pagination-number {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    min-width: 40px;
    padding: 8px 0;
  }
  .md-ui.component-pagination .pagination-number.current-number {
    background:  #FF7379;
    color: white;
  }
  .md-ui.component-pagination .pagination-arrow i {
    font-size: 24px;
    padding: 8px;
  }
.background_none{
  background: none!important;
}