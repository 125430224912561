@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.shop__container{
    display: flex;
    position: relative;
    height: 30vh;
    margin-bottom: 5vh;
}
.shop__image{
    position: relative;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 30%;
    background-image: url("../../../assets/search__background.png");
}
.shop__discription{
    margin-right: 5vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5vw;
}
.shop__discription h2{
font-family: 'Poppins', sans-serif;
font-weight: 500;
font-size: 24px;
line-height: 36px;
color: #22262A;
}
.shop__discription p{
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;

color: #686868;
}

.shop__discription h4{

    font-size: 14px;
    line-height: 21px;
    color: #262626;
}
.shop__visit{
background: rgba(51,160, 255, 0.1);
color: #1F7AC9;
font-family: 'Poppins', sans-serif;
font-size: 14px;
width: 20%;
text-align: center;
font-weight: 500;
text-decoration: none;
height: 20%;
display: flex;
align-items: center;
justify-content: center;
}