.search__container{
    display: flex;
    width: 100%;
    min-height: 70vh;
    align-items: center;
    justify-content: center;
    padding-left: 5vw;
}
.search__aside{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;   
}
.search__spacer{
    height: 5vh;
    background-color: #F6F7F8;
}
.search__components h2{
padding-top: 5vh;
padding-bottom: 5vh;
padding-left: 4vw;
font-size: 18px;
line-height: 21px;
text-decoration: underline;
color: #000000;
}
.search__aside{
    padding-top: 5vh;
    padding-left: 2vw;
    padding-right: 2vw;
}